import axios from "axios";
import {EAuthUrl} from "./services/Auth/Auth";

const BASE_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + "/api"
  : "/api";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, accessToken: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(accessToken);
    }
  });

  failedQueue = [];
};

const client = axios.create({
  baseURL: BASE_URL,
});

client.interceptors.request.use(config => {
  const accessToken = localStorage.getItem("accessToken");

  client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      originalRequest.url !== EAuthUrl.LOGIN &&
      !originalRequest._retry &&
      error.response.status === 401
    ) {
      if (isRefreshing) {
        return await new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject});
        })
          .then(async accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return await client(originalRequest);
          })
          .catch(async err => {
            return await Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = window.localStorage.getItem("refreshToken");
      return await new Promise(function (resolve, reject) {
        client
          .get(EAuthUrl.REFRESH, {params: {token: refreshToken}})
          .then(({data}) => {
            window.localStorage.setItem("accessToken", data.accessToken);
            window.localStorage.setItem("refreshToken", data.refreshToken);
            client.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            processQueue(null, data.accessToken);
            resolve(client(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return await Promise.reject(error);
  },
);

export default client;
